export const color = {
  lightBrown: "#7b705e",
  brown: "#4C4338",
  oceanBlue: "#4C88DD",
  lightGrey: "#A6A6A6",
  darkGrey: "#717171",
  gold: "#FFD700",
  silver: "#D3D3D3",
  bronze: "#CD7F32",
  white: "#FFFFFF",
  devPurple: "#b62ff0",
  royalePurple: "#b62ff0",
  discordPurple: "#7289da",
  darkDiscordPurple: "#5e78d5",
  steamPurple: "#1b2838",
  darkSteamPurple: "#171a21",
  twitterBlue: "#1DA1F2",
  darkGreen: "rgb(1, 128, 0)",
  lightGreen: `rgb(113, 246, 72)`,
  googleRed: `rgb(210, 52, 44)`,
  facebookBlue: `rgb(51, 80, 163)`,
  emailPurple: `rgb(87, 37, 106)`,
  darkRed: "#580000",
  red: "red",
  blue: "#4C70BA",
  lightBlue: "#D3DCEE",
  commonRarityDark: "white",
  rareRarityDark: "#157feb",
  epicRarityDark: "#c34aef",
  commonRarityLight: "white",
  rareRarityLight: "#e1f4ff",
  epicRarityLight: "#f7e1ff",
  shardRed: "rgb(251, 0, 61)",
  shardDarkRed: "rgb(151, 0, 43)",
  black: "black",
  coinYellow: "#FAD02C",
  backgroundYellow: "rgb(255, 236, 63)",
  lightBackgroundYellow: "rgb(255, 250, 230)",
  textOrange: "rgb(255, 168, 0)",
}
