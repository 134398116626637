
import { MainPage } from "./pages/MainPage"

const App = () => {
  
  return (
    <>
      <MainPage />
    </>
  )
}

export default App
